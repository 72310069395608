import React, { useState, useEffect } from 'react';
import DataListInput from 'react-datalist-input';
import { find, get } from 'lodash-es';

const PlayersList = ({ onSelectHandler, onRemoveHandler, player, items, index }) => {
  const [nickname, setNickname] = useState('');

  useEffect(() => {
    const label = get(find(items, ['key', player]), 'label');
    setNickname(label || '');
  }, [items, player, setNickname]);

  const match = (currentInput, item) => item.label.toLowerCase().includes(currentInput.toLowerCase());

  return (
    <div className="d-flex flex-row">
      <DataListInput
        items={items}
        onSelect={(value) => onSelectHandler(value, index)}
        value={nickname}
        match={match}
        inputClassName="p-1"
        activeItemClassName="bg-dark text-white p-1"
        itemClassName="p-1"
        suppressReselect={false}
        placeholder="Inserisci giocatore"
      />
      {player && <button type="button" className="btn focus-none" onClick={() => onRemoveHandler(player)}>
      <i class="fas fa-trash mr-2" aria-hidden="true"></i>
      </button>}
    </div>
  );
};

export default PlayersList;
