import React from 'react';

function Argument (props) {
	return (

		<div className="card border-dark mt-5 ml-5 mb-3" style={{maxWidth: "540px"}}>
			<div className="row g-0">
			    <div className="col-md-4 border-right">
			    	<img src={props.image} className={`w-100 ${props.imageP}`}  alt='Image' />
			    </div>
				<div className="col-md-8">
					<div className="card-body m-2">
						<div className="row">
							<h5 className="card-title">{props.title}</h5>
							<p className="card-text">{props.description}</p>
						</div>
						<div className="row mt-4">
							<div className="col-md-12 d-flex flex-row-reverse">
								<form action={props.link} target="_blank">
									<button type="submit" className="btn btn-outline-primary mt-4">{props.callToAction}</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		)
}

export default Argument;