import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { pull } from 'lodash-es';
import Team from './Team';
import { PlayersContext } from '../../../../context';

const AddHistoryModal = () => {
  const [createdAt, setCreatedAt] = useState();
  const [winners, setWinners] = useState([]);
  const [losers, setLosers] = useState([]);
  const addHistoryModalRef = useRef();
  const players = useContext(PlayersContext);
  const [today, now] = new Date().toISOString().split('T');

  const items = useMemo(
    () =>
      players.map((p) => ({
        label: p.nickname,
        key: p.id,
      })),
    [players],
  );

  const onSelectPlayer = useCallback(
    (player, index, win) => {
      if (win) {
        const updatedPlayers = [...winners];
        updatedPlayers[index] = player.key;
        setWinners(updatedPlayers);
      } else {
        const updatedPlayers = [...losers];
        updatedPlayers[index] = player.key;
        setLosers(updatedPlayers);
      }
    },
    [losers, setLosers, setWinners, winners],
  );

  const onRemovePlayer = useCallback(
    (id, win) => {
      if (win) {
        const updatedPlayers = [...winners];
        pull(updatedPlayers, id);
        setWinners(updatedPlayers);
      } else {
        const updatedPlayers = [...losers];
        pull(updatedPlayers, id);
        setLosers(updatedPlayers);
      }
    },
    [losers, setLosers, setWinners, winners],
  );

  const onChangeDate = useCallback(
    (event) => {
      setCreatedAt(`${event.target.value}T${now}`);
    },
    [setCreatedAt, now],
  );

  const onAddHistoryHandler = useCallback(async () => {
    try {
      await axios.post(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/history/game`, {
        game: { createdAt },
        teams: [
          {
            name: 'Winner',
            winner: true,
            players: winners,
          },
          {
            name: 'Loser',
            winner: false,
            players: losers,
          },
        ],
      });
      const modal = window.bootstrap.Modal.getInstance(addHistoryModalRef.current);
      modal.hide();
    } catch (e) {
      console.log(e);
    }
  }, [addHistoryModalRef, createdAt, losers, winners]);

  return (
    <div
      className="modal fade"
      id="newGame"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref={addHistoryModalRef}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              <b>Aggiungi allo storico</b>
            </h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="row m-4 mb-0">
              <div className="alert alert-primary" role="alert">
                Prima di aggiungere una partita controlla che non sia già stata inserita nello storico.
              </div>
              <p className="mt-3 mb-3">Inserisci le informazioni della partita:</p>
              <div className="row mb-5">
                <div className="col-md-4">
                  <span>
                    <b>Data</b>
                  </span>
                  <div className="input-group">
                    <input type="date" id="date" className="p-1" onChange={onChangeDate} max={today} />
                  </div>
                </div>
                <div className="col-md-4">
                  <span>
                    <b>Team vincitore</b>
                  </span>
                  <Team {...{ items, onSelectPlayer, onRemovePlayer, players: winners, win: true }} />
                </div>
                <div className="col-md-4">
                  <span>
                    <b>Team perdente</b>
                  </span>
                  <Team {...{ items, onSelectPlayer, onRemovePlayer, players: losers }} />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button type="button" className="btn btn-outline-secondary border-0" data-bs-dismiss="modal">
                Annulla
              </button>
              <button type="button" className="btn btn-primary" onClick={() => onAddHistoryHandler()}>
                Aggiungi partita
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHistoryModal;
