import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from '../common/header/header';
import Navbar from '../common/navbar/structure/navbar_structure';
import Page_picknbans from './picknbans/page/page_picknbans';
import Page_history from './history/structure/page/page_history';
import Page_extra from './extra/card/structure/extra_structure';

const Home = () => {
  return (
    <>
      <Header />
      <div className="row">
        <Navbar />
        <div className="col-md-10 f-14 p-3">
          <Switch>
            <Redirect exact from="/" to="/picknban" />
            <Route path="/picknban" component={Page_picknbans} />
            <Route path="/history" component={Page_history} />
            {/*<Route path="/maps" component={Page_map} />*/}
            <Route path="/:id" component={Page_extra} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Home;
