import paladino from '../../../../img/visual/paladino.jpg';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { GameContext } from '../../../../context';

const SetResultModal = () => {
  const [selectedTeam, setSelectedTeam] = useState();
  const setResultModalRef = useRef();
  const game = useContext(GameContext);
  const { Teams: teams, id, end } = game;

  useEffect(() => {
    if (!teams || !teams.length || (teams[0].id !== selectedTeam && teams[1].id !== selectedTeam)) {
      setSelectedTeam(undefined);
    }
  }, [teams, selectedTeam, setSelectedTeam]);

  const onChangeHandler = ({ id }) => setSelectedTeam(id);

  const onSetResultHandler = useCallback(async () => {
    try {
      await axios.put(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/game/${id}/winner`, {
        teamId: selectedTeam,
      });
      const modal = window.bootstrap.Modal.getInstance(setResultModalRef.current);
      modal.hide();
    } catch (e) {
      console.log(e);
    }
  }, [id, selectedTeam, setResultModalRef]);

  return (
    <div
      className="modal fade"
      id="setResoults"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref={setResultModalRef}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              <b>Registra i risultati di fine partita</b>
            </h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            {teams && (
              <div className="row m-4 mb-0">
                <div className="col-md-8">
                  <span>
                    Seleziona il team <b>vincitore</b>:
                  </span>
                  <h6 className="mt-3 ml-2">
                    {!!teams[0] && (
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="winTeam"
                          id="radioTeam1"
                          value={teams[0].id}
                          checked={selectedTeam === teams[0].id}
                          onChange={() => onChangeHandler(teams[0])}
                        />
                        <label className="form-check-label" htmlFor="radioTeam1">
                          {teams[0].Players.map(({ nickname }) => nickname).join(', ')}
                        </label>
                      </div>
                    )}
                    {!!teams[1] && (
                      <div className="form-check  mb-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="winTeam"
                          id="radioTeam2"
                          value={teams[1].id}
                          checked={selectedTeam === teams[1].id}
                          onChange={() => onChangeHandler(teams[1])}
                        />
                        <label className="form-check-label" htmlFor="radioTeam2">
                          {teams[1].Players.map(({ nickname }) => nickname).join(', ')}
                        </label>
                      </div>
                    )}
                  </h6>
                </div>
                <div className="col-md-4 d-flex align-items-end flex-row-reverse p-0">
                  <img src={paladino} className="w-75" alt="Paladino" />
                </div>
              </div>
            )}
          </div>
          {end && <p className="mt-3">Partita già registrata.</p>}
          {end && <p className="mt-3">Risultati della partita già salvati.</p>}
          <div className="modal-footer border-0">
            <button type="button" className="btn btn-outline-secondary border-0" data-bs-dismiss="modal">
              Annulla
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={end || !selectedTeam}
              onClick={onSetResultHandler}
            >
              Aggiorna la classifica
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetResultModal;
