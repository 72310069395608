import React, { useEffect, useMemo, useState } from 'react';
import DataListInput from 'react-datalist-input';
import { filter, map } from 'lodash-es';

function Teams_pop({ populations, selected = '', onSelect, label, type, pos }) {
  const [population, setPopulation] = useState();

  useEffect(() => {
    if (population !== selected) {
      setPopulation(selected);
    }
  }, [population, selected]);

  const items = useMemo(
    () =>
      map(filter(populations, ['selected', false]), (p, i) => ({
        label: p.name,
        key: i,
      })),
    [populations],
  );

  const match = (currentInput, item) => item.label.toLowerCase().includes(currentInput.toLowerCase());

  return (
    <DataListInput
      items={items}
      onSelect={(selected) => onSelect(selected.label, label, type, pos)}
      value={population}
      match={match}
      inputClassName="border-0 bg-transparent"
      activeItemClassName="bg-dark text-white p-1"
      itemClassName="p-1"
      suppressReselect={false}
    />
  );
}

export default Teams_pop;
