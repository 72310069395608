import React from 'react';

import castle from '../../../img/visual/castle.jpg';
import logo_ex from '../../../img/login/logo_ex.svg';
import bg_login from '../../../img/login/bg_login.svg';
import googleButton from './btn_google_signin_dark_normal_web.png';

function Login_structure() {
  return (
    <div className="container-fluid p-0">
      <div className="row vh-100 bg-login" style={{ backgroundImage: 'url(' + bg_login + ')' }}>
        <div className="col-md-4 d-flex align-items-end pb-5">
          <img src={castle} className="w-75" alt="Castle" />
        </div>
        <div className="col-md-4 text-center mt-5">
          <img className="mt-5 mb-5" src={logo_ex} style={{ height: '350px' }} alt="Logo" />
          <div className="d-flex justify-content-center mt-5">
            <a href={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/auth/google`}>
              <img src={googleButton} alt="Sign in with Google" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login_structure;
