import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import TableArgument from '../table_argument/table_argument.js';
import { GameContext, PlayersContext } from '../../../../context';

const Table_structure = ({ socket }) => {
  const [options, setOptions] = useState({});
  const game = useContext(GameContext);
  const players = useContext(PlayersContext);
  useEffect(() => {
    const getOptions = async () => {
      const response = await axios.get(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/teams`);
      setOptions(response.data);
    };

    getOptions();
  }, []);

  useEffect(() => {
    socket.on('teams', (data) => {
      setOptions(data);
    });
  }, [socket]);

  const setTeamHandler = useCallback(
    async (teams, option) => {
      try {
        await axios.put(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/game/${game.id}/teams`, {
          teams,
          option,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [game],
  );

  const clearTeamsHandler = useCallback(
    async (teams) => {
      try {
        await axios.put(
          `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/team/${teams[0]}/clear`,
          {},
        );
        await axios.put(
          `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/team/${teams[1]}/clear`,
          {},
        );
      } catch (e) {
        console.log(e);
      }
    },
    [],
  );

  return (
    <div className="col-md-2 m-0 p-0">
      {Object.values(options).map((teamOption, index) => (
        <TableArgument
          key={index}
          teams={teamOption}
          players={players}
          selected={game.option}
          setTeamHandler={setTeamHandler}
          clearTeamsHandler={clearTeamsHandler}
        />
      ))}
    </div>
  );
};

export default Table_structure;
