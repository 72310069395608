import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HistoryArgument from '../../argument/history/history_argument.js';

import marble from '../../../../img/header_marble.jpg';

const Tab_map = () => {
  const [history, setHistory] = useState([]);
  useEffect(() => {
    async function getGames() {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/games`);
        setHistory(data);
      } catch (e) {
        console.log(e);
      }
    }
    getGames();
  }, [setHistory]);
  console.log(history);
  const gamesNumber = history.length;
  return (
    <div className="row">
      <div className="col-md-12 mt-3 pl-5">
        <div className="rounded-top text-center pt-1 pb-1" style={{ backgroundImage: 'url(' + marble + ')' }}>
          <p className="text-white fw-bold mb-1 mt-1">Storico</p>
        </div>
        <div className="overflow-auto pnb-scroll">
          <table className="table table-sm border border-2 border-dark mt-1 mb-0">
            <thead className="table-dark">
              <tr>
                <th scope="col">N°</th>
                <th scope="col">Data</th>
                <th scope="col">Team 1</th>
                <th scope="col">Team 2</th>
                <th scope="col">Mappa</th>
              </tr>
            </thead>
            <tbody id="match-history">
              {history.map(({ id, ...game }, index) => (
                <HistoryArgument key={id} code={gamesNumber - index} {...game} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Tab_map;
