import React, { useContext } from 'react';
import { GameContext } from '../../../context';
import { getTotalPicks } from '../../../common/utils';
import ChangeMapModal from './modals/changeMapModal';
import NewGameModal from './modals/newGameModal';
import SetResultModal from './modals/setResultModal';

const Title = ({ createdAt, end, picks }) => (
  <div className="col-md border-bottom ml-5 p-0 pb-2">
    <h4>
      <b>
        Partita del {new Date(createdAt).toLocaleDateString()}
        {end && <span> - Risultati registrati</span>}
      </b>
    </h4>
    <h6>
      {picks.players / 2} vs {picks.players / 2} - Mappa: Casuale di terra
      <span
        style={{ color: '#2A9CF4' }}
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title="Altopiano, Cenote, Fiumi, Mongolia, Oasi, Valle, Yucatan, Anello d'acqua, Città dei laghi"
      >
        <i className="far fa-question-circle fa-sm ml-1" />
      </span>
    </h6>
  </div>
);

const Toolbar = () => {
  const game = useContext(GameContext);

  return (
    <div className="row mt-3 mb-4 ml-1 mr-1">
      {game && <Title {...game} />}
      <div className="col-md-6 d-flex justify-content-end border-bottom mr-5 pt-3 pr-0">
        <div className="mr-4">
          <button
            type="button"
            className={`btn border-0 ${game && game.end ? 'btn-outline-primary' : 'btn-outline-dark'}`}
            data-bs-toggle="modal"
            data-bs-target="#newGame"
          >
            <i className="fas fa-plus mr-2" />
            <b>Nuova partita</b>
          </button>
        </div>
        {game && (
          <>
            <div className="mr-4">
              <button
                id="primary-disabled"
                type="button"
                className={`btn border-0 ${(game.Teams.length &&
                  getTotalPicks(game.picks['Team1'], game.picks['Team2']) === game.picks.players &&
                  !game.end)
                   ? 'btn-outline-primary' : 'btn-outline-dark'}`}
                data-bs-toggle="modal"
                data-bs-target="#setResoults"
                disabled={
                  !game.Teams.length ||
                  getTotalPicks(game.picks['Team1'], game.picks['Team2']) !== game.picks.players ||
                  game.end
                }
              >
                <i className="fas fa-crown mr-2" />
                <b>Registra i risultati</b>
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-outline-dark border-0"
                data-bs-toggle="modal"
                data-bs-target="#changeMap"
                disabled={game.end}
              >
                <i className="fas fa-sync-alt mr-2" />
                <b>Cambia mappa</b>
              </button>
            </div>
          </>
        )}
      </div>

      <NewGameModal />
      {game && (
        <>
          <ChangeMapModal />
          <SetResultModal />
        </>
      )}
    </div>
  );
};

export default Toolbar;
