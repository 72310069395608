import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import marble from '../../../img/header_marble.jpg';
import { GameContext } from '../../../context';

const Tab_map = () => {
  const [selectedTeam, setSelectedTeam] = useState();
  const game = useContext(GameContext);
  const { Teams: teams, end, map, id } = game;

  useEffect(() => {
    if (!teams.length || (teams[0].id !== selectedTeam && teams[1].id !== selectedTeam)) {
      setSelectedTeam();
    }
  }, [selectedTeam, setSelectedTeam, teams]);

  const onChangeHandler = ({ id }) => setSelectedTeam(id);

  const onClickHandler = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/game/${id}/winner`, {
        teamId: selectedTeam,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="col-md-2 p-0 pl-3">
      <div className="rounded-top text-center pt-1 pb-1" style={{ backgroundImage: 'url(' + marble + ')' }}>
        <p className="text-white fw-bold mb-1 mt-1">Mappa</p>
      </div>
      <table className="table border border-2 border-dark mt-1 mb-0">
        <tbody>
          <tr>
            <td id="select_map">{map}</td>
          </tr>
        </tbody>
      </table>
      <div className="accordion accordion-flush d-none" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button bg-white border-white text-dark collapsed p-0 mt-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              <i className="fas fa-crown mr-2" />
              <b>Registra i risultati</b>
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse border-0"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body border border-1 rounded-1 p-3 pt-0 mt-3">
              <p className="mt-3 mb-2">Seleziona il team che ha vinto:</p>
              {!!teams[0] && (
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="winTeam"
                    id="radioTeam1"
                    value={teams[0].id}
                    checked={selectedTeam === teams[0].id}
                    onChange={() => onChangeHandler(teams[0])}
                  />
                  <label className="form-check-label" htmlFor="radioTeam1">
                    {teams[0].Players.map(({ nickname }) => nickname).join(', ')}
                  </label>
                </div>
              )}
              {!!teams[1] && (
                <div className="form-check  mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="winTeam"
                    id="radioTeam2"
                    value={teams[1].id}
                    checked={selectedTeam === teams[1].id}
                    onChange={() => onChangeHandler(teams[1])}
                  />
                  <label className="form-check-label" htmlFor="radioTeam2">
                    {teams[1].Players.map(({ nickname }) => nickname).join(', ')}
                  </label>
                </div>
              )}
              {/*<div className="accordion-body p-0 mt-3">
                <p className="mt-4 mb-2">Seleziona le popolazioni che hanno vinto:</p>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="winPop" id="radioPop1" />
                  <label className="form-check-label" for="radioPop1">
                    Cinesi, Maya, Unni
                  </label>
                </div>
                <div className="form-check  mb-2">
                  <input className="form-check-input" type="radio" name="winPop" id="radioPop2" />
                  <label className="form-check-label" for="radioPop2">
                    Cinesi, Maya, Unni
                  </label>
                </div>
              </div>*/}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm mt-4 mb-2"
                disabled={end || !selectedTeam}
                onClick={onClickHandler}
              >
                Aggiorna la classifica
              </button>
              {end && <p className="mt-3">Partita già registrata.</p>}
            </div>
          </div>

          {end && <p className="mt-3">Risultati della partita già salvati.</p>}
        </div>
      </div>
    </div>
  );
};

export default Tab_map;
