import React, { useCallback, useContext, useRef, useState } from 'react';
import axios from 'axios';
import { GameContext } from '../../../../context';

const NewGameModal = () => {
  const [players, setPlayers] = useState(8);
  const [civ1, setCiv1] = useState('');
  const [civ2, setCiv2] = useState('');
  const newGameModalRef = useRef();

  const game = useContext(GameContext);

  const handlePlayersSelect = (value) => {
    setPlayers(value);
  };

  const handleCiv1 = (value) => {
    setCiv1(value);
  };

  const handleCiv2 = (value) => {
    setCiv2(value);
  };

  const newGameHandler = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/game`,
        {
          civs: [civ1, civ2],
          players,
        },
        { withCredentials: true },
      );
      const modal = window.bootstrap.Modal.getInstance(newGameModalRef.current);
      modal.hide();
    } catch (e) {
      console.log(e);
    }
  }, [newGameModalRef, civ1, civ2, players]);

  return (
    <div
      className="modal fade"
      id="newGame"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref={newGameModalRef}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              <b>Crea nuova partita</b>
            </h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            {!game?.end && (
              <div className="alert alert-danger mt-2 mb-5" role="alert">
                I risultati della partita precedente <b>non sono stati registrati</b> e cliccando su "Crea nuova
                partita" verranno persi.
              </div>
            )}
            <div className="row m-4 mb-0">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <h6>
                      <b>Numero giocatori</b>
                    </h6>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="squadre"
                        value="4"
                        id="flexRadioDefault6"
                        checked={players === 4}
                        onChange={() => handlePlayersSelect(4)}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault6">
                        2 vs 2
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="squadre"
                        value="6"
                        id="flexRadioDefault7"
                        checked={players === 6}
                        onChange={() => handlePlayersSelect(6)}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault7">
                        3 vs 3
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="squadre"
                        value="8"
                        id="flexRadioDefault8"
                        checked={players === 8}
                        onChange={() => handlePlayersSelect(8)}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault8">
                        4 vs 4
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8 border rounded p-4">
                    <h6>
                      <b>Popolazioni extra del giorno</b>
                    </h6>
                    <div className="d-inline-flex">
                      <div className="dropdown">
                        <button
                          className="btn pl-0 dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ minWidth: '100px' }}
                        >
                          Popolazione 1:&#x000A0;
                          {civ1}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv1('Berberi')}>
                              Berberi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv1('Birmani')}>
                              Birmani
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv1('Etiopi')}>
                              Etiopi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv1('Khmer')}>
                              Khmer
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv1('Malesi')}>
                              Malesi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv1('Maliani')}>
                              Maliani
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv1('Portoghesi')}>
                              Portoghesi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv1('Vietnamiti')}>
                              Vietnamiti
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="dropdown pl-3">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Popolazione 2: &#x000A0;
                          {civ2}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv2('Berberi')}>
                              Berberi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv2('Birmani')}>
                              Birmani
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv2('Etiopi')}>
                              Etiopi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv2('Khmer')}>
                              Khmer
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv2('Malesi')}>
                              Malesi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv2('Maliani')}>
                              Maliani
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv2('Portoghesi')}>
                              Portoghesi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={() => handleCiv2('Vietnamiti')}>
                              Vietnamiti
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*<div className="col-md-4">
                    <h6>
                      <b>Popolazioni</b>
                    </h6>
                    <div className="dropdown disabled">
                      <button
                        className="btn disabled pl-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Tutte
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>
                          <a className="dropdown-item" href="#">
                            Lista1
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Lista2
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Lista3
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>*/}
                  {/*<div className="col-md-4">
                    <h6>
                      <b>Tipologia di mappa</b>
                    </h6>
                    <div className="dropdown disabled">
                      <button
                        className="btn disabled pl-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Casuale di terra
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>
                          <a className="dropdown-item" href="#">
                            Casuale
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Casuale di terra
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Casuale di mare
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0">
            <button type="button" className="btn btn-outline-secondary border-0" data-bs-dismiss="modal">
              Annulla
            </button>
            <button type="button" className="btn btn-primary" onClick={() => newGameHandler()}>
              Crea nuova partita
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewGameModal;
