import React from 'react';

import TabHistory from '../tab_history/tab_history_structure.js';
import TabStat from '../tab_stat/tab_stat_structure.js';
import ToolbarHistory from '../../toolbar_history/toolbar_history.js';

function History_structure() {
  return (
    <div className="row">
      <ToolbarHistory />
      <div className="col-md-7">
        <TabHistory />
      </div>
      <div className="col-md-5">
        <TabStat />
      </div>
    </div>
  );
}

export default History_structure;
