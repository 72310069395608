import React, { useContext } from 'react';
import { UserContext } from '../../context';
import marble from '../../img/header_marble.jpg';
import logo_header from '../../img/logo_header.svg';
import user_circle from '../../img/user_icon.svg';

function Header() {
  const user = useContext(UserContext);
  return (
    <div className="row header" style={{ backgroundImage: 'url(' + marble + ')' }}>
      <div className="col-md-4 d-flex flex-row align-items-center">
        <img className="ml-4" style={{ height: '32px', width: '25px' }} src={logo_header} alt="logo" />
        <h5 className="text-white ml-2 mb-0">Pick & Ban</h5>
      </div>
      <div className="col-md-4 offset-md-4 d-flex flex-row justify-content-end align-items-center">
        <img style={{ height: '25px' }} src={user_circle} alt="user" />
        <li className="dropdown pr-4">
          <a
            className="dropdown-toggle text-decoration-none text-white"
            id="navbarDarkDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {user.nickname}
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
            <li>
              <a className="dropdown-item disabled" href="/profile" tabIndex="-1" aria-disabled="true">
                Profilo
              </a>
            </li>
            <li>
              <a
                href={`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/auth/logout`}
                className="dropdown-item"
              >
                Disconnetti
              </a>
            </li>
          </ul>
        </li>
      </div>
    </div>
  );
}

export default Header;
