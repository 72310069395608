import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { GameContext } from '../../../context';
import Toolbar from '../toolbar/toolbar.js';
import Teams from '../teams/teams_structure/teams_structure.js';
import TabPop from '../tab_pop/tab_pop.js';
import TabMap from '../tab_map/tab_map.js';
import OptionsStructure from '../options/table_structure/table_structure.js';

const socket = io(`${process.env.REACT_APP_WS}${process.env.REACT_APP_API_HOST}`);

const Page_picknbans = () => {
  const [game, setGame] = useState();

  const getLastGame = useCallback(
    async () =>
      await axios.get(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/game/last`, {
        withCredentials: true,
      }),
    [],
  );

  useEffect( () => {
    async function lastGame() {
      try {
        const {
          data: {game},
        } = await getLastGame();
        setGame(game);
      } catch (e) {
        console.log(e);
      }
    }
    lastGame();
  }, [getLastGame, setGame]);

  useEffect(() => {
    socket.on('game', (data) => {
      setGame(data);
    });
    return () => {
      socket.off('game');
    };
  }, []);

  return (
    <GameContext.Provider value={game}>
      <div>
        <Toolbar />
        {game && game.id && (
          <div className="row overflow-auto pnb-scroll m-0 p-0 mt-3 pl-5" id="pnb">
            <OptionsStructure {...{ socket }} />
            <Teams />
            <TabPop />
            <TabMap />
          </div>
        )}
      </div>
    </GameContext.Provider>
  );
};

export default Page_picknbans;
