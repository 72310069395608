import React from 'react';
import {NavLink} from 'react-router-dom';

function NavbarArgument(props) {
	return (
			<div className="row m-0 mt-3">
				<NavLink to={props.link} className="unselected-navbar" activeClassName="selected-navbar">
					<i className={ `ml-4 mr-3 ${props.icon}`}></i>
					<b>{props.title}</b>
				</NavLink>
			</div>
		)
}

export default NavbarArgument;