import React from 'react';
import marble from '../../../../img/header_marble.jpg';
import TeamPop from '../teams_pop/teams_pop';

const TeamArgument = ({ players, populations, team, onSelect, setting }) => {
  const { title, label, numbers } = setting;
  return (
    <div className="col-md-5 p-0 pr-3">
      <div className="rounded-top text-center pt-1 pb-1" style={{ backgroundImage: 'url(' + marble + ')' }}>
        <p className="text-white fw-bold mb-1 mt-1">{title}</p>
      </div>
      <table className="table table-sm border border-2 border-dark mt-1 mb-0">
        <tbody>
          <tr className="paletteRed">
            <th style={{ width: '75px' }}>{numbers[0]}° Ban</th>
            <td>
              <TeamPop
                populations={populations}
                selected={team.banned[1]}
                onSelect={onSelect}
                label={label}
                type="banned"
                pos="1"
              />
            </td>
          </tr>
          <tr className="paletteRed">
            <th style={{ width: '75px' }}>{numbers[1]}° Ban</th>
            <td>
              <TeamPop
                populations={populations}
                selected={team.banned[2]}
                onSelect={onSelect}
                label={label}
                type="banned"
                pos="2"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table border border-2 border-dark mt-1 mb-0">
        <tbody>
          <tr className="paletteGreen">
            <th style={{ width: '75px' }}>{numbers[2]}° Pick</th>
            <td>
              <TeamPop
                populations={populations}
                selected={team.picked[1]}
                onSelect={onSelect}
                label={label}
                type="picked"
                pos="1"
              />
            </td>
          </tr>
          <tr className="paletteGreen">
            <th style={{ width: '75px' }}>{numbers[3]}° Pick</th>
            <td>
              <TeamPop
                populations={populations}
                selected={team.picked[2]}
                onSelect={onSelect}
                label={label}
                type="picked"
                pos="2"
              />
            </td>
          </tr>
        </tbody>
      </table>
      {players > 4 && (
        <>
          <table className="table border border-2 border-dark mt-1 mb-0">
            <tbody>
              <tr className="paletteRed">
                <th style={{ width: '75px' }}>{numbers[4]}° Ban</th>
                <td>
                  <TeamPop
                    populations={populations}
                    selected={team.banned[3]}
                    onSelect={onSelect}
                    label={label}
                    type="banned"
                    pos="3"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table border border-2 border-dark mt-1 mb-0">
            <tbody>
              <tr className="paletteGreen">
                <th style={{ width: '75px' }}>{numbers[5]}° Pick</th>
                <td>
                  <TeamPop
                    populations={populations}
                    selected={team.picked[3]}
                    onSelect={onSelect}
                    label={label}
                    type="picked"
                    pos="3"
                  />
                </td>
              </tr>
              {players > 6 && (
                <tr className="paletteGreen">
                  <th style={{ width: '75px' }}>{numbers[6]}° Pick</th>
                  <td>
                    <TeamPop
                      populations={populations}
                      selected={team.picked[4]}
                      onSelect={onSelect}
                      label={label}
                      type="picked"
                      pos="4"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default TeamArgument;
