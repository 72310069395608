import React, { useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import DataListInput from 'react-datalist-input';

function Table_players_argument({ players, selected = [{ nickname: '' }], teamId, position }) {
  const [nickname, setNickname] = useState();

  useEffect(() => {
    if (nickname !== selected[0].nickname) {
      setNickname(selected[0].nickname);
    }
  }, [nickname, selected]);

  const items = useMemo(
    () =>
      players.map((p) => ({
        label: p.nickname,
        key: p.id,
      })),
    [players],
  );

  const onSelect = useCallback(async (selectedItem) => {
    try {
      await axios.put(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/team/${teamId}/add`, {
        playerId: selectedItem.key,
        position,
      });
    } catch (e) {
      console.log(e);
    }
  }, [position, teamId]);

  const match = (currentInput, item) => item.label.toLowerCase().includes(currentInput.toLowerCase());

  return (
    <DataListInput
      items={items}
      onSelect={onSelect}
      value={nickname}
      match={match}
      inputClassName="border-0 bg-transparent"
      activeItemClassName="bg-dark text-white p-1"
      itemClassName="p-1"
      suppressReselect={false}
    />
  );
}
export default Table_players_argument;
