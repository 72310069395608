import React, { useState } from 'react';

import NavbarArgument from '../argument/navbar_argument';

const Navbar_structure = () => {
  const [NavbarArguments] = useState([
    { link: '/picknban', icon: 'fas fa-balance-scale', title: 'Pick & Ban' },
    { link: '/history', icon: 'fas fa-trophy', title: 'Storico e stat.' },
    /*{link: '/maps', icon: 'fas fa-landmark', title: 'Mappe e civiltà'},*/
    { link: '/extra', icon: 'fas fa-bookmark', title: 'Extra' },
  ]);

  return (
    <div className="col-md-2 navigation-menu fw-bold p-0 pt-3 pr-5">
      {NavbarArguments.map((argument, index) => (
        <NavbarArgument key={index} link={argument.link} icon={argument.icon} title={argument.title} />
      ))}
    </div>
  );
};

export default Navbar_structure;
