import React, { useCallback, useContext, useRef } from 'react';
import axios from 'axios';
import { GameContext } from '../../../../context';

const ChangeMapModal = () => {
  const changeMapModalRef = useRef();
  const { id } = useContext(GameContext);

  const onChangeMapHandler = useCallback(async () => {
    try {
      await axios.put(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/game/${id}/map`, {});
      const modal = window.bootstrap.Modal.getInstance(changeMapModalRef.current);
      modal.hide();
    } catch (e) {
      console.log(e);
    }
  }, [id, changeMapModalRef]);

  return (
    <div
      className="modal fade"
      id="changeMap"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref={changeMapModalRef}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              <b>Cambia mappa</b>
            </h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="row m-4 mb-0">
              <div className="col-md-12">
                <span>
                  Prima di cambiare mappa, ti sei <b>confrontato</b> con gli altri giocatori?
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0">
            <button type="button" className="btn btn-outline-secondary border-0" data-bs-dismiss="modal">
              Annulla
            </button>
            <button type="button" className="btn btn-primary" onClick={() => onChangeMapHandler()}>
              Si, cambia mappa
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeMapModal;
