import React from 'react';

const history_argument = ({ loss, nickname, plays, wins, winrate }) => {
  return (
    <tr>
      <td className="border-right">{nickname}</td>
      <td className="border-right">{plays}</td>
      <td className="border-right">{wins}</td>
      <td className="border-right">{loss}</td>
      <td className="border-right">{winrate || '-'}</td>
    </tr>
  );
};

export default history_argument;
