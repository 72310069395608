import React, { useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../../context';
import catafratto from '../../../img/visual/catafratto.jpg';
import logo_outline from '../../../img/login/logo_outline.svg';
import bg_login from '../../../img/login/bg_nickname.svg';

function Login_structure() {
  const [nickname, setNickname] = useState();
  const history = useHistory();
  const { id } = useContext(UserContext);

  const handleSetNickname = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/player/${id}/nickname`,
        {
          nickname,
        },
        { withCredentials: true },
      );
      history.push('/');
    } catch (e) {
      console.log(e);
      setNickname('');
    }
  }, [history, id, nickname]);

  const handleKeyPress = async ({ charCode }) => {
    if (charCode === 13) {
      await handleSetNickname();
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="row vh-100 bg-login" style={{ backgroundImage: 'url(' + bg_login + ')' }}>
        <div className="col-md-4 offset-md-4 text-center mt-5">
          <div className="mt-5 mb-4">
            <img src={logo_outline} style={{ height: '82px' }} alt="Logo" className="mt-5" />
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h3 className="mb-3">Inserisci il tuo nickname:</h3>
              <div className="input-group mb-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Esempio: Attila"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setNickname(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </div>
              <div className="alert alert-primary" role="alert">
                In futuro non potrai cambiarlo.
              </div>
              <div className="d-grid gap-2 col-7 mx-auto text-center mt-4">
                <button type="button" className="btn btn-primary" id="login" onClick={() => handleSetNickname()}>
                  Conferma nickname
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex align-items-end pb-5">
          <img src={catafratto} className="w-75" alt="catafratto" />
        </div>
      </div>
    </div>
  );
}

export default Login_structure;
