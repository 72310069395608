import React, { useContext } from 'react';
import { map, orderBy } from 'lodash-es';
import StatArgument from '../../argument/stat/stat_argument.js';
import { PlayersContext } from '../../../../context';

import marble from '../../../../img/header_marble.jpg';

const Tab_stat_structure = () => {
  const players = useContext(PlayersContext);
  const sortedPlayers = orderBy(players, [(player) => +player.winrate, (player) => +player.plays], ['desc', 'desc']);
  return (
    <div className="row">
      <div className="col-md-10 offset-md-1 mt-3">
        <div className="rounded-top text-center pt-1 pb-1" style={{ backgroundImage: 'url(' + marble + ')' }}>
          <p className="text-white fw-bold mb-1 mt-1">Statistiche</p>
        </div>
        <table className="table table-sm border border-2 border-dark mt-1 mb-0">
          <thead className="table-dark">
            <tr>
              <th>Giocatore</th>
              <th>P. totali</th>
              <th>Vinte</th>
              <th>Perse</th>
              <th>Win rate</th>
            </tr>
          </thead>
          <tbody>
            {map(sortedPlayers, ({ id, ...player }) => (
              <StatArgument key={id} {...player} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Tab_stat_structure;
