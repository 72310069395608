import React, { useContext } from 'react';
import { forEach, includes, set } from 'lodash-es';
import marble from '../../../img/header_marble.jpg';
import { GameContext } from '../../../context';
import { getUnavailableCivs } from '../../../common/utils';

const Tab_pop = () => {
  const { civs: populations = [], picks } = useContext(GameContext);
  const team1 = picks['Team1'];
  const team2 = picks['Team2'];
  const unavailableCivs = getUnavailableCivs(team1, team2);
  forEach(populations, (civ) => set(civ, 'selected', includes(unavailableCivs, civ.name)));
  const tab = () => {
    const row = [];

    let k = 0;

    const rows = () => {
      const cell = [];
      for (let j = 0; j < 2; j++) {
        cell.push(
          <td key={j} className={populations[k].selected ? 'paletteRed' : ''}>
            {' '}
            {populations[k].name}{' '}
          </td>,
        );
        k++;
      }
      return cell;
    };

    for (let i = 0; i < populations.length / 2; i++) {
      row.push(<tr key={i}>{rows()}</tr>);
    }
    return row;
  };

  return (
    <div className="col-md-2 p-0">
      <div className="rounded-top text-center pt-1 pb-1" style={{ backgroundImage: 'url(' + marble + ')' }}>
        <p className="text-white fw-bold mb-1 mt-1">Draft popolazioni</p>
      </div>
      <table className="table table-sm table-bordered border border-2 border-dark mt-1 mb-0">
        <tbody id="testBody">{tab()}</tbody>
      </table>
    </div>
  );
};

export default Tab_pop;
