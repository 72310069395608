import React, { useCallback, useContext } from 'react';
import { forEach, includes, set } from 'lodash-es';
import axios from 'axios';
import TeamArgument from '../team_argument/team_argument';
import { GameContext } from '../../../../context';
import { getUnavailableCivs } from '../../../../common/utils';

const TEAM_SETTINGS = [
  {
    title: 'Team 1',
    label: 'Team1',
    numbers: [1, 4, 2, 3, 6, 5, 8],
  },
  {
    title: 'Team 2',
    label: 'Team2',
    numbers: [2, 3, 1, 4, 5, 6, 7],
  },
];

const Team_structure = () => {
  const game = useContext(GameContext);

  const onSelectHandler = useCallback(
    async (population, team, type, pos) => {
      const { picks } = game; //TODO clone
      picks[team][type][pos] = population;
      try {
        await axios.put(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/game/${game.id}`, {
          picks,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [game],
  );

  if (!game) {
    return null;
  }

  const { civs, picks } = game;
  const { players } = picks;
  const team1 = picks['Team1'];
  const team2 = picks['Team2'];
  const unavailableCivs = getUnavailableCivs(team1, team2);
  forEach(civs, (civ) => set(civ, 'selected', includes(unavailableCivs, civ.name)));
  return (
    <div className="col-md-4 offset-md-1 pl-3">
      <div className="row">
        <TeamArgument
          populations={civs}
          team={team1}
          onSelect={onSelectHandler}
          setting={TEAM_SETTINGS[0]}
          {...{ players }}
        />
        <TeamArgument
          populations={civs}
          team={team2}
          onSelect={onSelectHandler}
          setting={TEAM_SETTINGS[1]}
          {...{ players }}
        />
      </div>
    </div>
  );
};

export default Team_structure;
