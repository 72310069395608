import React, { useCallback, useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import axios from 'axios';
import { PlayersContext, UserContext } from './context';
import loginPage from './components/login/structure/login_structure.js';
import nicknamePage from './components/login/nickname/nickname.js';
import Home from './components/home';
import './css/bootstrap.css';
import './css/style.css';

function App() {
  const [loggedUser, setLoggedUser] = useState(null);
  const [players, setPlayers] = useState([]);
  const history = useHistory();

  const getUser = useCallback(
    async () =>
      await axios.get(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/profile`, {
        withCredentials: true,
      }),
    [],
  );

  useEffect(() => {
    async function getPlayers() {
      const response = await axios.get(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_API_HOST}/players`);
      setPlayers(response.data);
    }
    getPlayers();
  }, [setPlayers]);

  useEffect(() => {
    async function user() {
      try {
        const { data } = await getUser();
        setLoggedUser(data);
        history.push('/picknban');
      } catch (e) {
        console.log(e);
        history.push('/login');
      }
    }
    user();
  }, [getUser, history, setLoggedUser]);

  useEffect(() => {
    if (!!loggedUser && !loggedUser.nickname) {
      history.push('/nickname');
    }
  }, [history, loggedUser]);

  return (
    <UserContext.Provider value={loggedUser}>
      <div className="container-fluid">
        <Switch>
          <Route exact path="/login" component={loginPage} />
          <Route exact path="/nickname" component={nicknamePage} />
          {loggedUser && loggedUser.nickname && (
            <PlayersContext.Provider value={players}>
              <Route path="/">
                <Home />
              </Route>
            </PlayersContext.Provider>
          )}
        </Switch>
      </div>
    </UserContext.Provider>
  );
}

export default App;
