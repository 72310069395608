import React from 'react';

import marble from '../../../../img/header_marble.jpg';
import TableDropdown from '../table_dropdown/table_dropdown.js';
import PlayerDropdown from '../table_players_argument/table_players_argument.js';
const OPTIONS = [1, 2, 3, 4];

const Table_argument = ({ teams, players, selected, setTeamHandler, clearTeamsHandler }) => {
  const [team1, team2] = teams;
  const { option } = team1;
  const teamOptions = OPTIONS.map((position) => {
    return (
      <tr key={option + position}>
        <td>
          <PlayerDropdown players={players} selected={team1.Players[position]} teamId={team1.id} position={position} />
        </td>
        <td>
          <PlayerDropdown players={players} selected={team2.Players[position]} teamId={team2.id} position={position} />
        </td>
      </tr>
    );
  });

  return (
    <div className="col-md-12 m-0 p-0 pb-4">
      <div className="tab-header d-flex rounded-top pt-1 pb-1" style={{ backgroundImage: 'url(' + marble + ')' }}>
        <div className="flex-grow-1">
          <p className="text-white fw-bold mb-1 mt-1 pl-2">
            Opzione {option}
            {option === selected && <span> - Team scelti</span>}
          </p>
        </div>
        <TableDropdown
          option={option}
          teams={teams.map((team) => team.id)}
          onClickHandler={setTeamHandler}
          onClearFieldsHandler={clearTeamsHandler}
        />
      </div>
      <table className="table table-sm table-bordered border border-2 border-dark mt-1 mb-0">
        <thead className="tab-header bg-dark text-white">
          <tr>
            <th>{team1.name}</th>
            <th>{team2.name}</th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: option === selected ? '#DEE8F9' : '' }}>{teamOptions}</tbody>
      </table>
    </div>
  );
};

export default Table_argument;
