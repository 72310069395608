import React, { useState } from 'react';

import Argument from '../argument/extra_argument';
import discord from '../../../../img/extra/discord.png';
import teamspeak from '../../../../img/extra/teamspeak.png';

function Extra_structure() {
  const [Arguments] = useState([
    {
      image: discord,
      imageP: 'p-3',
      title: 'Discord - Ignobles',
      description: 'Principale canale di comunicazione.',
      link: 'https://discord.gg/3Rbv2G3K',
      callToAction: 'Unisciti a Discord',
    },
    {
      image: teamspeak,
      imageP: 'p-5',
      title: 'Teamspeak - Wolveslair',
      description: 'Canale di comunicazione secondario. Indirizzo: wolveslair.eu, nessuna password richiesta.',
      link: 'https://teamspeak.com/en/downloads/',
      callToAction: 'Scarica Teamspeak',
    },
  ]);

  return (
    <div>
      {Arguments.map((argument, index) => (
        <Argument
          key={index}
          image={argument.image}
          imageP={argument.imageP}
          title={argument.title}
          description={argument.description}
          link={argument.link}
          callToAction={argument.callToAction}
        />
      ))}
    </div>
  );
}

export default Extra_structure;
