import React from 'react';
import PlayersList from './PlayersList';

const Team = ({ players, win, onSelectPlayer, onRemovePlayer, ...props }) => {
  const [p1, p2, p3, p4] = players;

  const onSelectHandler = (player, index) => {
    onSelectPlayer(player, index, win);
  };

  const onRemoveHandler = (id) => {
    onRemovePlayer(id, win);
  };

  return (
    <>
      <PlayersList {...{ onSelectHandler, onRemoveHandler, player: p1, index: 0 }} {...props} />
      {p1 && <PlayersList {...{ onSelectHandler, onRemoveHandler, player: p2, index: 1 }} {...props} />}
      {p2 && <PlayersList {...{ onSelectHandler, onRemoveHandler, player: p3, index: 2 }} {...props} />}
      {p3 && <PlayersList {...{ onSelectHandler, onRemoveHandler, player: p4, index: 3 }} {...props} />}
    </>
  );
};

export default Team;
