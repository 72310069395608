import React from 'react';

import altro from '../../../../img/altro.svg';

const Table_dropdown = ({ teams, option, onClickHandler, onClearFieldsHandler }) => {
  return (
    <div className="d-flex align-items-center 1 mr-3">
      <li className="dropdown">
        <img
          className="dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          src={altro}
          style={{ height: '20px' }}
          alt="Altro"
        />
        <ul className="dropdown-menu" aria-labelledby="">
          <li>
            <a
              className="dropdown-item"
              onClick={() => onClickHandler(teams, option)}
              tabIndex="-1"
              aria-disabled="true"
            >
              Conferma team
            </a>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          {/*<li><a className="dropdown-item" href="#">Inverti team</a></li>
				        <li><a className="dropdown-item" href="#">Duplica</a></li>*/}
          <li>
            <a className="dropdown-item" onClick={() => onClearFieldsHandler(teams)}>
              Pulisci campi
            </a>
          </li>
          {/*<li><a className="dropdown-item" href="#">Resetta tutte le opzioni</a></li>*/}
        </ul>
      </li>
    </div>
  );
};

export default Table_dropdown;
