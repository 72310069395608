import React from 'react';
import AddHistoryModal from './modals/addHistoryModal';

const Toolbar_history = () => {
  return (
    <div className="row m-0 mb-3 p-0">
      <div className="col-md m-0 p-0">
        <div className="col-md d-flex justify-content-end border-bottom mr-5 ml-5 p-0 pt-3 pb-3">
          <button
            type="button"
            className="btn btn-outline-primary border-0"
            data-bs-toggle="modal"
            data-bs-target="#newGame"
          >
            <i className="fas fa-plus mr-2" />
            <b>Aggiungi allo storico</b>
          </button>
        </div>
      </div>
      <AddHistoryModal />
    </div>
  );
};

export default Toolbar_history;
