import React from 'react';

const pickCivs = ({ picked }) => Object.values(picked).join(', ');
const pickPlayers = ({ Players = [] }) => Players.map(({ nickname }) => nickname).join(', ');

const history_argument = ({ code, createdAt, map = '-', picks, Teams }) => {
  const formattedDate = new Date(createdAt).toLocaleDateString();
  const [team1 = {}, team2 = {}] = Teams;
  const win1 = team1?.winner;
  const win2 = team2?.winner;
  return (
    <tr>
      <td className="border-right">{code}</td>
      <td className="border-right">{formattedDate}</td>
      <td className={`border-right ${win1 ? 'paletteGreen' : 'paletteRed'}`}>
        <span>{pickPlayers(team1)}</span>
        {picks && <span className="civilisations">{pickCivs(picks['Team1'])}</span>}
      </td>
      <td className={`border-right ${win2 ? 'paletteGreen' : 'paletteRed'}`}>
        <span>{pickPlayers(team2)}</span>
        {picks && <span className="civilisations">{pickCivs(picks['Team2'])}</span>}
      </td>
      <td>{map || '-'}</td>
    </tr>
  );
};

export default history_argument;
